form.application-form {
}

form.application-form input {
}

.MuiPaper-root {
  padding: 1em;
  background-color: #f4f4f5 !important;
}

.MuiFormControl-root {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  display: flex !important;
}

.MuiFormLabel-root {
  display: inline-flex !important;
}

.MuiOutlinedInput-root {
  background-color: #fff;
}

/*MHP 20231211: Hide the menu and breadcrumbs since this will be called from another site now; 
    we don't want to link back to the legacy routes.*/ 
div.nav-header-menu-visible {
    display: none; 
}

div.nav-breadcrumbs-container {
    display: none;
}